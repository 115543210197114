/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Logo from "./logo"
import Menu from "./menu"

import Footer from "./footer"

import "./layout.css"

const Layout = ({
  children,
  backgroundColor,
  textColor,
  overrideBackgroundColor,
  overrideTextColor,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <>
        {/* <Logo textColor={textColor} /> */}
        {/* <Menu textColor={textColor} /> */}
        <main>{children}</main>
        <Footer
          backgroundColor={backgroundColor}
          textColor={textColor}
          overrideBackgroundColor={overrideBackgroundColor}
          overrideTextColor={overrideTextColor}
        />
      </>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
